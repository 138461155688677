declare global {
    interface Window {
        google: any;
    }
}

/**
 * Autocomplete on Google Maps search field (contact page)
 */
export default (googleMapsHolderSelector = '.locationSearchAddress') => {
    const searchAddressField = document.querySelector(googleMapsHolderSelector);
    if (searchAddressField) {
        searchAddressField.addEventListener('focus', () => initAutocompleteMapsInput(searchAddressField), {
            once: true,
            capture: true,
        });
    }
};

function initAutocompleteMapsInput(inputField: Element) {
    if (window.google && inputField !== null) {
        new window.google.maps.places.Autocomplete(inputField, { componentRestrictions: { country: 'be' } });
    }
}
