import * as tingle from 'tingle.js';
import 'tingle.js/dist/tingle.min.css';

export default (buttonSelector: string) => {
    const button = document.querySelector(buttonSelector);
    if (!button) {
        return;
    }

    button.addEventListener('click', function (e) {
        e.preventDefault();
        const modal = new tingle.modal({
            closeMethods: ['overlay', 'button', 'escape'],
            closeLabel: 'Sluiten',

            // Override with custom close icon
            onOpen: function () {
                const iconWrapper = document.createElement('div');
                iconWrapper.classList.add('icon-close');
                iconWrapper.innerHTML = `<svg height="30" viewBox="0 0 30 30" width="30" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="square"><path d="m1.518519 1.518519 26.962963 26.962963"/><path d="m28.481482 1.518519-26.962963 26.962963"/></g></svg>`;
                modal.modalCloseBtnIcon.innerHTML = iconWrapper.outerHTML;
            },
            onClose: () => modal.destroy(),
        });

        // Add an iframe with the content of our anchor
        modal.setContent(
            `<iframe width="100%" height="800" src="${this.href}" frameborder="0" allowfullscreen></iframe>`,
        );
        modal.open();
        return false;
    });
};
