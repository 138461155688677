const HASH_PREFIX = 'tab';

export default (menuSelector: string = '.menu-tabs') => {
    const tabMenus = document.querySelectorAll('.menu-tabs');
    [...tabMenus].forEach((tabMenu: Element) => {
        // Add click event listeners
        const tabButtons = tabMenu.querySelectorAll(`${menuSelector} a.btn-tab`);
        if (tabButtons.length > 0) {
            [...tabButtons].forEach((button) => {
                button.addEventListener('click', (e: Event) => {
                    e.preventDefault();
                    showTab(e.currentTarget! as Element, tabMenu);
                    return false;
                });
            });
        }

        // Change to the right tab based on the hash
        if (window.location.hash) {
            const ref = window.location.hash.replace(`${HASH_PREFIX}_`, '');
            const button = document.querySelector(`${menuSelector} a[href="${ref}"]`);
            const parentTabMenu = button?.parentElement?.parentElement;
            if (button && parentTabMenu) {
                showTab(button, parentTabMenu);
            }
        }
    });
};

function showTab(buttonElement: Element, tabMenu: Element, updateHash = true) {
    const targetTabId = buttonElement.getAttribute('href')!;

    // Start showing the tab on screen after click
    const tabContent = getNextSibling(tabMenu, '.tab-content');
    const tabPaneTarget = tabContent?.querySelector(`.tab-pane${targetTabId}`);
    if (tabContent && tabPaneTarget) {
        // Remove active classes from currently showing tab, only from direct children!
        [...tabContent.querySelectorAll(`:scope > .tab-pane.fade.in.active`)].forEach((tabPane) =>
            tabPane.classList.remove('in', 'active'),
        );

        // Activate the new tab
        tabPaneTarget.classList.add('active');
        setTimeout(() => tabPaneTarget.classList.add('in'), 100); // Wait with the fade-in until block is showing!

        // Mark button as active
        [...tabMenu.querySelectorAll('.active')].forEach((element) => element.classList.remove('active'));
        buttonElement.parentElement?.classList.add('active');

        // Set the url hash
        if (updateHash) {
            window.location.hash = `${HASH_PREFIX}_${targetTabId.slice(1)}`;
        }
    }
}

function getNextSibling(elem: Element, selector: string) {
    // Get the next sibling element
    var sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
}
