export default (tooltipSelector: string = '[data-tooltip]') => {
    const tooltipTriggers = document.querySelectorAll(tooltipSelector);
    if (tooltipTriggers.length === 0) {
        return;
    }

    [...tooltipTriggers].forEach((trigger) => initTooltip(trigger));
};

function initTooltip(tooltipTrigger: Element) {
    // On mouse hover, create a tooltip
    tooltipTrigger.addEventListener(
        'mouseover',
        () => {
            const { tooltipTitle } = tooltipTrigger.dataset;

            const tooltipInstance = document.createElement('div');
            tooltipInstance.classList.add('tooltip', 'fade');
            tooltipInstance.innerHTML = tooltipTitle;
            document.body.appendChild(tooltipInstance);
            tooltipInstance.style.display = 'block';
            setTimeout(() => tooltipInstance.classList.add('visible'), 100);
        },
        false,
    );

    // On mouse out, delete tooltips
    tooltipTrigger.addEventListener(
        'mouseout',
        () => {
            $$('.tooltip').forEach((item) => item.remove());
        },
        false,
    );

    // Tooltip follows the mouse
    tooltipTrigger.addEventListener(
        'mousemove',
        (e) => {
            const mouseX = e.pageX + 20;
            const mouseY = e.pageY + 10;
            const tooltipInstance = $('.tooltip');
            tooltipInstance.style.top = `${mouseY}px`;
            tooltipInstance.style.left = `${mouseX}px`;
        },
        false,
    );
}

function $(selector: string, scope = document) {
    return scope.querySelector(selector);
}

function $$(selector: string, scope = document) {
    return Array.from(scope.querySelectorAll(selector));
}
