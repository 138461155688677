import 'intersection-observer';
import * as quicklink from 'quicklink';

const MOBILE_NAV_CLASS = 'mobile-nav';

export default (): void => {
    // Add support for css on mobile navigation
    // (document.querySelector('html') as HTMLHtmlElement).classList.add('mobile-nav');

    // Enable GoogleChromeLabs/quicklink
    quicklink.listen();

    // Build the mobile navigation
    buildMobileNav();
};

/**
 * Build the mobile navigation DOM
 */
function buildMobileNav() {
    const trigger = document.querySelector('.js-main-nav-trigger') as HTMLDivElement;
    const navMainHolder = document.querySelector('.header .main-nav') as HTMLDivElement;
    const navMetaHolder = document.querySelector('.header .meta-nav') as HTMLDivElement;
    if (!navMainHolder || !navMetaHolder) {
        return;
    }

    // Clone the main nav items
    const mobileMainNavItems = [...navMainHolder.childNodes]
        .map((element) => element.cloneNode(true) as HTMLElement)
        .filter((element) => element.nodeName === 'UL');

    // Clone the CTA buttons
    const mobileMainNavCTAItems = [...navMainHolder.childNodes]
        .map((element) => element.cloneNode(true) as HTMLElement)
        .filter((element) => element.nodeName === 'A');

    // Create list items of the meta nav
    const mobileMetaNavItems = [...navMetaHolder.querySelectorAll<HTMLUListElement>('ul:not(.social-media-list)')]
        .map((element) => element.cloneNode(true))
        .map((element) => {
            return [...element.childNodes]
                .filter((element) => element.nodeName === 'LI')
                .reduce((acc, element) => {
                    element.classList.add('mobile-meta-item');
                    acc.appendChild(element);
                    return acc;
                }, document.createElement('ul'));
        });

    // Erase the mobile nav if its already there
    cleanupMobileNav();

    // Insert the mobile nav in the DOM
    const mobileNavigation = document.createElement('nav');
    mobileNavigation.classList.add(`${MOBILE_NAV_CLASS}`, 'hidden');
    document.querySelector('.header.inner')?.insertAdjacentElement('afterend', mobileNavigation);
    [...mobileMainNavItems, ...mobileMetaNavItems, ...mobileMainNavCTAItems].forEach((element: Element) => {
        mobileNavigation.insertAdjacentElement('beforeend', element);
    });

    // Mobile nav trigger: Hide or show the mobile navigation
    trigger?.addEventListener('click', function () {
        mobileNavigation?.classList.toggle('hidden');
        this.classList.toggle('is-active'); // For https://jonsuh.com/hamburgers/
    });
}

function cleanupMobileNav() {
    document.querySelectorAll(`.${MOBILE_NAV_CLASS}`).forEach((e) => e.parentNode?.removeChild(e));
}
